<template>
  <div class="layout me">

    <!-- 패널 -->
    <component :is="Panel" :mode="`me`" :key="user.login" />

    <!-- 헤더 -->
    <component :is="Header" :mode="`me`" />

    <!-- 컨텐츠 -->
    <div :class="`contents ${resolution.width > resolution.notebook ? `` : `mobile`}`">
      <div class="contents-inside">
        <component :is="meLnb" v-if="resolution.width > resolution.notebook" :meMenus="meMenus" />
        <div class="contents-inside-right">
          <component :is="ErrorUI" v-if="error" :state="error" :key="`${error}-error`" />
          <component :is="meBar" v-if="resolution.width <= resolution.notebook" :meMenus="meMenus" />
          <div class="contents-inside-section">
            <slot />
          </div>
        </div>
      </div>
    </div>

    <!-- 푸터 -->
    <component :is="Footer" :mode="`me`" />

  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
import { useGlobalStore } from '~/store/global'
// import Error from '~/component/state/error.vue'
// import Panel from '~/component/element/panel.vue'
// import Header from '~/component/layout/header.vue'
// import Footer from '~/component/layout/footer.vue'
// import meLnb from '~/component/me/lnb.vue'
// import meBar from '~/component/me/bar.vue'

export default {
  async setup () {

    // 글로벌 스토어
    const { user } = storeToRefs(useAuthStore())
    const { error, resolution } = storeToRefs(useGlobalStore())
    let $i18n: any = ref(globalVariables.app.i18n)

    // 메뉴 설정
    const router = useRouter()
    const meMenus: any = ref(globalVariables.meLnb)

    function updateMeMenus () {
      meMenus.value.items = globalFunctions.getTranslateText(meMenus.value.items, $i18n.value)
      meMenus.value.items = meMenus.value.items.map((item: any) => ({
        ...item,
        selected: item.key === router.currentRoute?.value?.name
      }))
      meMenus.value.tabs = meMenus.value.items.find((item: any) => {
        return item.selected
      })?.value || 0
    }
    updateMeMenus()

    // 메모리 비우기
    function restore () {
      ErrorUI.value = null
      Panel.value = null
      Header.value = null
      Footer.value = null
      meLnb.value = null
      meBar.value = null
      meMenus.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const ErrorUI: any = shallowRef(null)
    const Panel: any = shallowRef(null)
    const Header: any = shallowRef(null)
    const Footer: any = shallowRef(null)
    const meLnb: any = shallowRef(null)
    const meBar: any = shallowRef(null)
    const loadComponent = async () => {
      const ErrorUI_load = await import('~/component/state/error.vue')
      const Panel_load = await import('~/component/element/panel.vue')
      const Header_load = await import('~/component/layout/header.vue')
      const Footer_load = await import('~/component/layout/footer.vue')
      const meLnb_load = await import('~/component/me/lnb.vue')
      const meBar_load = await import('~/component/me/bar.vue')
      ErrorUI.value = ErrorUI_load.default || ErrorUI_load
      Panel.value = Panel_load.default || Panel_load
      Header.value = Header_load.default || Header_load
      Footer.value = Footer_load.default || Footer_load
      meLnb.value = meLnb_load.default || meLnb_load
      meBar.value = meBar_load.default || meBar_load
    }
    await loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      user,
      error,
      resolution,
      meMenus,
      updateMeMenus,

      // 컴포넌트
      ErrorUI,
      Panel,
      Header,
      Footer,
      meLnb,
      meBar
    }
  },
  watch: {
    '$route' (to) {
      this.updateMeMenus()
    },
    '$i18n.locale' () {
      this.updateMeMenus()
    }
  }
}
</script>